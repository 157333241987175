import { __decorate } from "tslib";
import { Component, Watch } from 'vue-property-decorator';
import { Form, FormItem } from '@/components/shared/CForm/index';
import { TagType, OperateType, ShareType, SourceType, } from '../../../../typings/organization/label-manage';
import UpdateLabelBase from './update-label-base';
let UpdateLabel = class UpdateLabel extends UpdateLabelBase {
    async comfirm() {
        super.comfirm(ShareType.Org);
    }
    cancel() {
        super.cancel();
    }
    async orgChange() {
        super.orgChange();
    }
    async onValueChange(v) {
        if (v) {
            this.modalTitle = (this.operateType === OperateType.Add ? '添加' : '编辑') + '标签';
            const labeldata = this.labelDetail;
            this.loading = true;
            if (this.operateType === OperateType.Edit) {
                // 编辑标签
                const detail = await this.getDetail(labeldata.id);
                this.form.name = detail.name;
                this.form.org = detail.orgId;
                this.form.group = detail.parentId;
                this.isSelectedOrg = true;
                this.isSelectedGroup = true;
            }
            else {
                if (labeldata) {
                    if (labeldata.labelType === TagType.Org) {
                        this.isSelectedOrg = true;
                        this.isSelectedGroup = false;
                        this.form.org = labeldata.id;
                    }
                    else if (labeldata.labelType === TagType.Group) {
                        this.isSelectedOrg = true;
                        this.isSelectedGroup = true;
                        this.form.org = labeldata.parentId;
                        this.form.group = labeldata.id;
                    }
                }
            }
            const orgParams = {
                labelType: TagType.Org,
                shareType: ShareType.Org,
                labelId: '',
                sourceType: SourceType.SelfMaintain,
            };
            this.orgList = await this.getDataList(orgParams);
            if (this.form.org) {
                const groupParams = {
                    labelType: TagType.Group,
                    shareType: ShareType.Org,
                    labelId: this.form.org,
                    sourceType: SourceType.SelfMaintain,
                };
                this.groupList = await this.getDataList(groupParams);
            }
            this.loading = false;
        }
        else {
            this.form = {
                name: '',
                group: undefined,
                org: undefined,
            };
            this.orgList = [];
            this.groupList = [];
            this.isSelectedOrg = false;
            this.isSelectedGroup = false;
        }
    }
};
__decorate([
    Watch('value')
], UpdateLabel.prototype, "onValueChange", null);
UpdateLabel = __decorate([
    Component({
        name: 'UpdateLabel',
        components: {
            CForm: Form,
            CFormItem: FormItem,
        },
    })
], UpdateLabel);
export default UpdateLabel;
