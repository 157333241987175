import { __decorate } from "tslib";
import { Vue, Ref, Prop, Emit, Watch, } from 'vue-property-decorator';
import { LabelService } from '@/sevices';
import { TagType, OperateType, SourceType, } from '../../../../typings/organization/label-manage';
import * as Utils from '@/common/utils';
export default class UpdateLabelBase extends Vue {
    constructor() {
        super(...arguments);
        this.visible = false;
        this.isSelectedOrg = false;
        this.isSelectedGroup = false;
        this.loading = false;
        this.OperateType = OperateType;
        this.SourceType = SourceType;
        this.TagType = TagType;
        this.modalTitle = '...';
        this.submiting = false;
        this.form = {
            name: '',
            group: undefined,
            org: undefined,
        };
        this.params = {};
        this.orgList = [];
        this.groupList = [];
        this.groupName = '';
        this.rules = {
            name: [
                { required: true, message: '标签名称不能为空', trigger: 'blur' },
                {
                    validator: (value) => /^[\u4e00-\u9fa5_a-zA-Z0-9\-()]{0,30}$/.test(value),
                    message: '支持输入中英文和数字-_()，最大长度为30个字',
                    trigger: 'blur',
                },
            ],
            org: [
                { required: true, message: '上级组织不能为空', trigger: 'change' },
            ],
            group: '',
        };
    }
    get routeName() {
        return this.$route.name;
    }
    async comfirm(shareType) {
        const isValid = this.cform.validate();
        if (!isValid)
            return;
        try {
            this.submiting = true;
            this.operateType === OperateType.Add
                ? await this.addLabel(shareType)
                : await this.updateLabel();
        }
        finally {
            this.submiting = false;
        }
    }
    onFormChange() {
        Utils.trim(this.form);
    }
    created() {
    }
    async orgChange() {
        const params = {
            labelType: TagType.Group,
            labelId: this.form.org,
            shareType: TagType.Org,
            sourceType: SourceType.SelfMaintain,
        };
        this.form.group = undefined;
        this.groupList = await this.getDataList(params);
    }
    async addLabel(shareType) {
        const params = {
            orgId: this.form.org,
            parentId: this.form.group,
            name: this.form.name,
            labelType: TagType.Label,
            shareType: shareType,
        };
        const res = await LabelService.addLabelOrGroup(params);
        if (!res.success) {
            this.$message.error(res.errMessage);
        }
        this.reloadTree({
            data: res.data,
            type: 'add',
        });
        this.reload();
        this.cancel();
    }
    reload() { }
    reloadTree(data) {
        return data;
    }
    async updateLabel() {
        const params = {
            name: this.form.name,
            id: this.labelDetail.id,
            labelType: TagType.Label,
        };
        const res = await LabelService.upDateLabelOrGroup(params);
        if (!res.success) {
            this.$message.error(res.errMessage);
            return;
        }
        const data = res.data;
        this.$message.success('更新成功');
        this.reloadTree({
            data: data,
            type: 'update',
        });
        this.reload();
        this.cancel();
    }
    cancel() {
        this.$emit('input', false);
    }
    async getDataList(params) {
        const res = await LabelService.getOrgAndGroup(params);
        if (!res.success) {
            this.$message.error(res.errMessage);
        }
        return res.data || [];
    }
    async getDetail(id) {
        const { success, data, errMessage } = await LabelService.getLabelOrGroupDetail(id);
        if (!success) {
            this.$message.error(errMessage);
        }
        return data || {};
    }
}
__decorate([
    Prop()
], UpdateLabelBase.prototype, "value", void 0);
__decorate([
    Prop()
], UpdateLabelBase.prototype, "labelDetail", void 0);
__decorate([
    Prop({ default: OperateType.Add })
], UpdateLabelBase.prototype, "operateType", void 0);
__decorate([
    Ref()
], UpdateLabelBase.prototype, "cform", void 0);
__decorate([
    Watch('form', { deep: true })
], UpdateLabelBase.prototype, "onFormChange", null);
__decorate([
    Emit('reload')
], UpdateLabelBase.prototype, "reload", null);
__decorate([
    Emit('reloadTree')
], UpdateLabelBase.prototype, "reloadTree", null);
